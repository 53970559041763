import React, { useState, useEffect } from 'react';
import EditDate from './EditDate'; // Assuming EditDate is in the same directory
import fetchClientsMatters from '../functions/fetchClientsMatters';
import { generateCsrfToken } from '../Auth';
import { encrypt, decrypt } from '../functions/encryption';
import queryClio from '../functions/clio/queryClio';
import getLawcusClientsMatters from '../functions/lawcus/getClientsMatters';
import { CustomSelect } from './customSelect.js';
import Select from 'react-select';
import Modal from 'react-modal';
import '../styles/style.css';
import moment from 'moment-timezone';
import CryptoJS from 'crypto-js';
import devLog from '../functions/devLog';

Modal.setAppElement('#root'); // This line is needed for accessibility reasons

const SendEntriesToClio = ({ supabase, billingSoftware }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [clients, setClients] = useState([]);
    const [matters, setMatters] = useState([]);
    const [selectedClients, setSelectedClients] = useState(new Set());
    const [timezone, setTimezone] = useState('America/New_York'); // fetch this
    //const [startDate, setStartDate] = useState(moment.tz(timezone).startOf('day').toDate());
    //const [endDate, setEndDate] = useState(moment.tz(timezone).startOf('day').toDate());
    const [startDate, setStartDate] = useState(moment.utc().startOf('day').toDate());
    const [endDate, setEndDate] = useState(moment.utc().startOf('day').toDate());
    const [selectAll, setSelectAll] = useState('true');
    const [unverifiedEntries, setUnverifiedEntries] = useState([]);
    const [unverifiedDates, setUnverifiedDates] = useState(new Set());
    const [pastTransactions, setPastTransactions] = useState([]);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [loading, setLoading] = useState(true);
    const [session, setSession] = useState(null);

    const billing_sw_dict = {
        'lawcus': {
            'name': 'Lawcus',
            'get_clients': getLawcusClientsMatters,
            //'key': process.env.LAWCUS_ENCRYPTION_KEY
        },
        'clio': {
            'name': 'Clio',
            'get_clients': queryClio,
            //'key': process.env.REACT_APP_CLIO_ENCRYPTION_KEY
        },
    };
    const billingSoftwareName = billing_sw_dict[billingSoftware].name;

    useEffect(() => {
        async function fetch() {
            const session1 = (await supabase.auth.getSession()).data.session;
            setSession(session1);
            const { data: profileData, error: profileError } = await supabase
                .from('profiles')
                .select('timezone')
                .eq('profile_id', session1.user.id);
            if (profileError) {
                console.error('Error fetching profile:', profileError);
                return;
            }
            setTimezone(profileData[0].timezone);

            // fetch past transactions
            const { data: transactionsData, error: transactionsError } = await supabase
                .from('transactions_with_billing')
                .select('*')
                .eq('profile_id', session1.user.id);
            if (transactionsError) {
                console.error('Error fetching transactions:', transactionsError);
                return;
            }
            setPastTransactions(transactionsData);
            devLog('Past transactions:', transactionsData);
        }
        fetch();
        if (session) {
            fetchClientsMatters(setClients, setMatters, supabase, session);
            setLoading(false);
        }
    }, [modalOpen]);

    const openModal = () => {
        // fetch clients to ensure the access token is valid. The function will prompt the user to sign in again if necessary.
        if (session) {
            billing_sw_dict[billingSoftware].get_clients();
        }

        setModalOpen(true);
    };

    const closeModal = () => setModalOpen(false);

    const toggleClientSelection = (clientId) => {
        console.log("Toggling client selection")
        const newSelection = new Set(selectedClients);
        if (newSelection.has(clientId)) {
            newSelection.delete(clientId);
        } else {
            newSelection.add(clientId);
        }
        setSelectedClients(newSelection);
    };
    const toggleCollapse = (event) => {
        event.preventDefault();
        setIsCollapsed(!isCollapsed);
    };
    const handleClientChange = (selectedOptions) => {
        const selectedClientIds = new Set(selectedOptions.map(option => option.value));
        clients.forEach(client => {
            if (selectedClientIds.has(client.uuid)) {
                if (!selectedClients.has(client.uuid)) {
                    toggleClientSelection(client.uuid);
                }
            } else {
                if (selectedClients.has(client.uuid)) {
                    toggleClientSelection(client.uuid);
                }
            }
        });
    };

    const clientOptions = clients.map(client => ({
        value: client.uuid,
        label: client.name,
    }));

    const getClientNames = (clientIds) => {
        return clientIds.map(id => {
            const client = clients.find(client => client.uuid === id);
            return client ? client.name : id;
        }).join(', ');
    };

    const sendToClio = async ({event}) => {
        // Implementation of sending selected clients and dates to Clio

        event.preventDefault();
        devLog(`Sending to ${billingSoftware} with clients:`, Array.from(selectedClients), 'Dates:', startDate, endDate);

        // move endDate up one day
        let endDatePlusOne = new Date(endDate);
        endDatePlusOne.setDate(endDatePlusOne.getDate() + 1);

        // Convert date strings to UTC timestampz format
        const startDateFormat = "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ";
        const startDateUTC = moment.tz(startDate, startDateFormat, true, 'GMT').utc().format();
        const endDateUTC = moment.tz(endDate, startDateFormat, true, 'GMT').utc().format();
        const endDatePlusOneUTC = moment.tz(endDatePlusOne, startDateFormat, true, 'GMT').utc().format();

        // start and end dates are the correct values in UTC- so is the time column of the entries,
        // so we can compare them directly

        // fetch the entries between the selected dates
        const { data: entriesData, error: entriesError } = await supabase
            .from('block_entries')
            .select('*')
            //.in('client_id', Array.from(selectedClients))
            .gte('date', startDateUTC)
            .lt('date', endDatePlusOneUTC)
            .eq('profile_id', session.user.id)
            .eq('sent_to_billing_sw', false);
        if (entriesError) {
            console.error('Error fetching entries:', entriesError);
            return;
        }

        //console.log("Entries:")
        //console.log(entriesData)

        // take only the entries that belong to the selected clients
        let entries;
        if (selectAll === 'true') {
            entries = entriesData.filter(entry => !selectedClients.has(entry.client_num));
        } else {
            entries = entriesData.filter(entry => selectedClients.has(entry.client_num));
        }

        setUnverifiedEntries(entries.filter(entry => entry.need_to_verify_client || !entry.client_num || !entry.matter_num));
        setUnverifiedDates(new Set(unverifiedEntries.map(entry => entry.date)));

        // filter entries so each entry has a client and matter
        entries = entries.filter(entry => entry.client_num && entry.matter_num);
        
        /*for (let entry of entries) {
            devLog(entry);
            // if the client or matter is not filled in, we can't send it
            if (!entry.client_num || !entry.matter_num) {
                alert(`Make sure to fill in the client and matter for each entry.`)
                //closeModal();
                return;
            } // add option to just send entries with clients and matters filled in
            if (entry.need_to_verify_client) {
                alert(`Some clients have not been verified. Please verify all clients before sending to ${billingSoftwareName}.`)
                //closeModal();
                return;
            }
        }*/

        devLog("Entries to send:")
        devLog(entries)
        // if there are no entries, we don't need to send anything
        if (entries.length === 0) {
            alert(`No entries to send to ${billingSoftwareName}.`);
            //closeModal();
            return;
        }

        // format entries to send to the billing software
        let formattedEntries;
        if (billingSoftware === 'lawcus') {
            formattedEntries = entries.map(entry => ({
                description: entry.description,
                matter_id: parseInt(matters[entry.matter_num].lawcus_id),
                timestamp: entry.time_taken * 6 * 60 * 1000, // convert tenths of an hour to miliseconds
                date: moment(entry.time).format('YYYY-MM-DD HH:mm:ss'), // format the date
                user_id: entry.user_id,
                //duration: entry.time_taken * 6 * 60,
                is_non_billable: false,
            }));
        } else if (billingSoftware === 'clio') {
            formattedEntries = entries.map(entry => ({
                "date": entry.date,
                "note": entry.description,
                matter: {
                    id: entry.matter_num,
                },
                type: 'TimeEntry',
                quantity: entry.time_taken * 6 * 60, // convert tenths of an hour to seconds
                non_billable: false,
                billed: false,
                flat_fee: false,
            }));
        } else return;

        devLog("Formatted entries:")
        devLog(formattedEntries)

        const key = billing_sw_dict[billingSoftware].key;

        devLog("Sending to server.")

        const serverEndpoint = process.env.REACT_APP_DEPLOYED_BOOLEAN === 'true'
        ? `${process.env.REACT_APP_PRODUCTION_URL}/api/${billingSoftware}/send-entries`
        : `http://localhost:${process.env.REACT_APP_SERVER_PORT || 3000}/api/${billingSoftware}/send-entries`;

        const CSRF_PROTECTION_STRING = generateCsrfToken(); // to prevent CSRF attacks
        // store the protection string in supabase
        const { _, error } = await supabase
            .from('integrations')
            .update({ csrf_token: CSRF_PROTECTION_STRING })
            .eq('profile_id', session.user.id);
        if (error) {
            console.error('Error updating CSRF token in Supabase:', error);
            return;
        }

        let sentEntries;
        try {
            const id = session.user.id;
            //const encrypted_id = CryptoJS.AES.encrypt(id, key).toString();
            const encrypted_id = await encrypt(id, billingSoftware);
            const response = await fetch(serverEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Profile-ID': encrypted_id,
                    'X-CSRF-Token': CSRF_PROTECTION_STRING,
                },
                body: JSON.stringify({formattedEntries: formattedEntries}),
            });
            if (!response.ok) {
                devLog('Response:', response);
                console.error(`HTTP error! status: ${response.status} ${response.statusText}`);
                alert('Something seems to have gone wrong. Please reload the page and try again. Report the problem if it persists.');
                return;
            }
            const data = await response.json();
            devLog('Response from server:', data);

            sentEntries = data;
        } catch (error) {
            console.error('Error sending to Clio:', error);
        }

        if (!sentEntries) {
            alert('Internal error: No entries sent to Clio.');
            closeModal();
            return;
        }
        
        // check that sentEntries really does correspond to which entries were sent.
        const updatePromises = entries.map(async (entry, i) => {
            if (!sentEntries[i]) {
                return;
            }
            // mark the entries as sent
            const { error: updateError } = await supabase
                .from('block_entries')
                .update({ sent_to_billing_sw: true })
                .eq('uuid', entry.uuid);
            if (updateError) {
                console.error('Error updating entry:', updateError);
            }
        });

        console.log("Selected clients:")
        console.log(selectedClients)

        // add these entries to the transactions_with_billing table
        const { error: transactionError } = await supabase
            .from('transactions_with_billing')
            .insert({
                profile_id: session.user.id,
                entries: entries.map(entry => entry.uuid),
                selected_clients: Array.from(selectedClients),
                select_all: selectAll,
                start: startDate,
                end: endDate,
            });
        if (transactionError) {
            console.error('Error adding transaction:', transactionError);
        }

        await Promise.all(updatePromises);

        alert(`${formattedEntries.length} entries successfully sent to ${billing_sw_dict[billingSoftware].name}!`);
        closeModal(); // Close modal after sending entries
    };

    const handleStartDateChange = (e) => {
        let date = new Date(e.target.value);
        setStartDate(date);
        console.log("Start date:", date);
    };
    const handleEndDateChange = (e) => {
        let date = new Date(e.target.value);
        setEndDate(date);
        console.log("End date:", date);
    };

    return (
        <>
            <button className="button white left" onClick={openModal}>
                Send Entries to {billingSoftwareName}
            </button>

            <Modal
                isOpen={modalOpen}
                onRequestClose={() => {
                    setModalOpen(false);
                }}
                contentLabel="Send Entries to {billingSoftwareName}"
                style={{ overlay: { zIndex: 1000 } }}>
                    <form onSubmit={(event) => sendToClio({event})}>

                    <h2>Send Entries to {billingSoftwareName}</h2>
                    <div style={{display: 'flex'}}>
                    <h3>Send entries within this date range:</h3>
                    <label>
                        <div className="editDateWrapper">
                            <EditDate date={startDate} handleDateChange={handleStartDateChange} />
                        </div>
                    </label>
                    <label>
                        to
                        <div className="editDateWrapper">
                            <EditDate date={endDate} handleDateChange={handleEndDateChange} />
                        </div>
                    </label>
                    </div>

                    {/*<div className='flexbox' style={{'gap': '100px', alignItems: 'flex-end'}}>*/}
                    <div>
                        <CustomSelect
                            myVar={selectAll}
                            setVar={(value) => setSelectAll(value)}
                            value_to_display={{'true': "All clients", 'false': 'Only select clients'}}
                            defaultValue={'true'} defaultDisplay={"All clients"}
                            width={'300px'}/>
                    </div>
                    <h4 style={{'marginBottom': '0px'}}> {selectAll==='true' ? 'Select any clients to exclude:' : 'Select clients:'} </h4>

                    <div>
                        <label>
                            <Select
                                isMulti
                                options={clientOptions}
                                onChange={handleClientChange}
                                placeholder="Search for a client"
                            />
                        </label>
                    </div>

                    <div>
                        <div className='flexbox' style={{'gap': '20px'}}>
                            <h3>Past entries sent:</h3>
                            <button onClick={(e) => toggleCollapse(e)} className="button white square">
                                {isCollapsed ? '\u25B6' : '\u25BC'}
                            </button>
                        </div>

                        <div className={`collapsible-content ${isCollapsed ? 'collapsed' : 'expanded'}`}>
                            {pastTransactions.map((transaction, index) => (
                                <div key={index} className="transaction-box">
                                    <p>{moment.utc(transaction.start).format('MM/DD/YYYY')} - {moment.utc(transaction.end).format('MM/DD/YYYY')}</p>
                                    <p>
                                        {transaction.select_all
                                            ? (transaction.selected_clients.length > 0
                                                ? `All clients except: ${getClientNames(transaction.selected_clients)}`
                                                : 'All clients')
                                            : `Clients: ${getClientNames(transaction.selected_clients)}`
                                        }
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                    
                    {/*<div className="activities-list">
                        {selectedClients.size > 0 ? (
                        Array.from(selectedClients).map((uuid, index) => {
                            const client = clients.find(client => client.uuid === uuid);
                            return (
                                <div key={index} className="activity-item">
                                    <p style={{ margin: '0px' }}>{client ? client.name : uuid}</p>
                                    <button className="delete-button" onClick={() => toggleClientSelection(uuid)}>
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                            );
                        })
                        ) : (
                        <div> </div>
                        )}
                    </div>*/}
                    
                    {/*<div className="client-list">
                        {clients.map(client => (
                            <label key={client.uuid}>
                                <input
                                    type="checkbox"
                                    checked={selectedClients.has(client.uuid)}
                                    onChange={() => toggleClientSelection(client.uuid)}
                                />
                                {client.name}
                            </label>
                        ))}
                    </div>*/}
                    <br></br>
                    <button type="submit">Send Entries to {billingSoftwareName}</button>
                    <button type="button" onClick={() => {setModalOpen(false)}}>
                        Cancel
                    </button>
                    </form>
            </Modal>
        </>
    );
};

export default SendEntriesToClio;